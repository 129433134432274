/* AddCategory.css */

.form-container {
    /* max-width: 400px; */
    margin: auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    /* border: 1px solid green; */
    /* display: flex; */
}

.form-container form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.add-form-inp{
    /* border: 1px solid blue; */
}

.outside-add-form{
    /* border: 1px solid rgb(184, 0, 0); */
    width: 100%;
}

.inside-add-form{
    /* border: 1px solid rgb(221, 0, 255); */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-container label {
    display: block;
    margin-bottom: 8px;
}

.form-container input {
    /* width: 100%; */
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
}

.form-container button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px;
}

.form-container button:hover {
    background-color: #0056b3;
}

.add-form-last-btn{
    display: flex;
    justify-content: center;
}

.category-table img{
    /* border: 1px solid red; */
}

table img{
    /* border: 1px solid red; */
    height: 2rem;
    /* width: auto; */
    width: 150px;
}

.category-back-btn{
    /* Users.module.css */
    background-color: #343A40;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
  }

.category-back-btn:hover{
    background-color: #2184b4;
  }

.error-text{
    color: red;
}

.edit-button {
    /* color: black !important;  */
    color: #44E31E !important;
    /* color: rgb(0, 153, 204) !important; */
    /* background-color: #F5F5F5; */
    /* background-color: white; */
    background-color: transparent;
    font-size: 20px;
    width: 60px;
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .share-button{
     /* color: black !important;  */
     color: #44E31E !important;
     /* color: rgb(0, 153, 204) !important; */
     /* background-color: #F5F5F5; */
     /* background-color: white; */
     background-color: transparent;
     font-size: 20px;
     width: 60px;
     margin: 5px;
     border: none;
     cursor: pointer;
     transition: background-color 0.3s ease-in-out;
  }
  
  .delete-button {
    color: #dc3545 !important;
    /* background-color: #F5F5F5; */
    /* background-color: white; */
    background-color: transparent;
    font-size: 20px;
    width: 60px;
    margin: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
    /* background-color: #FEF4EA; */
}

.edit-modal {
    /* background-color: #fff; */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    /* text-align: center; */
    /* border: 1px solid red; */
    /* background-color: #FEF4EA; */
    /* background-color: #448C72; */
    /* background-color: #7ad6d6; */
    background-color: #FFFFFF;
}

.modal-close-button {
    border: 1px solid rgb(222, 221, 221);
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    /* background-color: transparent; */
    background-color: #C1C1C1;
    width: 60px;
}

.modal-close-button:hover{
    background-color: #949090;
    color: white;
    transition: background-color 0.3s ease-in-out;
}

.modal-save-button {
    border: 1px solid rgb(222, 221, 221);
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
    /* background-color: transparent; */
    background-color: #F15E5E;
    width: 60px;
}

.modal-save-button:hover{
    background-color: #ac3737;
    color: white;
    transition: background-color 0.3s ease-in-out;
}

.modal-last-div{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    gap: 5px;
}

.modal-header {
    margin-bottom: 20px;
}

.modal-header h2 {
    color: #333;
}

.modal-content h1{
    /* border: 1px solid red; */
    text-align: center;
    /* padding: 10px; */
    font-size: 80px;
    color: #F15E5E;
}

.modal-content h2{
    text-align: center;
}

.modal-content p {
    text-align: center;
}

.modal-content {
    display: grid;
    gap: 15px;
}

.modal-content label {
    font-weight: bold;
}

.modal-content input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.no-data-main{
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
}

.no-data-img{
    /* style={{ width: '80%', height: 'auto', maxHeight: '500px' }} */
    /* border: 1px solid red; */
    height: 300px;
    width: 50%;
}

@media screen and (max-width: 600px){
    .no-data-img{
        /* border: 1px solid red; */
        width: 100%;
    }
}

.category-images{
    /* border: 1px solid red; */
    border-radius: 4px;
    height: 100px !important;
    width: 100px !important;
    object-fit: cover !important;
  }

  /* Add this to your CSS file or a CSS module */
.table-cell {
    max-width: 150px; /* Adjust this value as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.status-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: gray; /* Gray dot by default */
}

.active {
    background-color: green; /* Green dot for active status */
}

.inactive {
    background-color: red; /* Red dot for inactive status */
}
