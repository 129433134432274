.order-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-details-container, .basket-details-container, .additional-orders-container, .order-status-container {
    background-color: #f5f5f5;
    padding: 20px;
    margin: 5px;
    /* border-radius: 8px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.order-status-select {
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 120px;
}

.product-image {
    max-width: 100%;
    /* height: auto; */
    height: 100px;
    border-radius: 4px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    background: gainsboro;
}

.head-every-data{
    color: #44e31e;
    border-bottom: 1px solid rgb(192, 190, 190);
    border-bottom-style: dashed;
}

.head-every-para{
    /* border: 1px solid red; */
    font-size: 20px;
    color: #707070;

}

.para-two-para{
    color: gray;
}

.order-status-container{
    /* border: 1px solid red; */
}

.order-status-inside-container{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}