/* Users.module.css */

.main-first-container{
  /* border: 1px solid red; */
  margin-top: 85px;
}

.main-data-tab {
    margin: 20px;
    /* border: 1px solid red; */
    /* margin-top: 100px; */
  }
  
  .users-main {
    border: 1px solid #e1e1e1;
    /* background-color: #343A40; */
    /* border-radius: 8px; */
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .users-main h2{
    /* border: 1px solid red; */
    margin: 10px;
  }
  
  .tab-buttons {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    background-color: #f8f8f8;
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
  }
  
  .tab-buttons button {
    cursor: pointer;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .tab-buttons button:hover {
    background-color: #e1e1e1;
  }
  
  .tab-buttons .active-tab {
    /* background-color: #343A40; */
    /* background-color: darkmagenta; */
    background-color: #44E31E;
    color: #fff;
  }

  /* Add your existing styles for table, th, and td here */

/* .odd-row {
  background-color: #f2f2f2; 
}

.even-row {
  background-color: #ffffff; 
}

.even-row:hover{
  background-color: #d6d6d6;
}

.odd-row:hover {
  background-color: #d6d6d6; 
} */

  
  table {
    width: 90%;
    margin: 0 auto;
    border-collapse: collapse;
    overflow: hidden;
  }
  
  table, th, td {
    border-bottom: 1px solid #e8e8e8;
    /* background-color: #F5F5F5; */
    /* background-color: white; */
  }

  table tr:hover{
    background-color: #f3f2f2;
  }

  .main-table-heads:hover{
    background: none !important;
  }
  
  th, td {
    padding: 12px;
    text-align: center;
    cursor: pointer;
  }
  
  th {
    /* background-color: #343A40; */
    /* background-color: darkblue; */
    /* background-color: rgb(236, 236, 236); */
    /* background-color: #FFFFFF; */
    /* color: #fff; */
    border-bottom: 1px solid #e8e8e8;
    color: black;
    /* border: none; */
    padding: 20px;
  }

  td{
    border-left: none;
    border-right: none;
    /* background-color: #F5F5F5; */
  }

 .view-users-main Button{
  background-color: black;
  color: white;
 }

 .view-users-main Button:hover{
  background-color: black;
  color: white;
 }
  
  /* UserCards.css */

.users-main {
  /* max-width: 1200px; */
  margin: 5px;
  background-color: #FFFFFF;
}

.user-fam-card {
  /* border: 1px solid red; */
  /* margin: 10px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Add this to your CSS file or style section */
.user-card p button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.user-card p button:hover {
  background-color: #0056b3;
}

.user-card {
  /* background-color: #fff; */
  /* background-image: url(../../../Assets//BG\ 5.png); */
  /* background: #D5ECF5; */
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
  padding: 20px;
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11);
  transition: box-shadow 0.3s ease;

  &:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  h3 {
      color: #44E31E;
      font-size: 1.2em;
      margin-bottom: 10px;
  }

  ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
          margin-bottom: 5px;
      }
  }

  p {
      color: #555;
      font-size: 1em;
      margin: 0;
  }
}

  
  /* Add more styles as needed for specific elements */

  .add-users-btn{
    /* border: 1px solid #dddedf; */
    /* border: 1px solid red; */
    background-color: white;
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
   }

   .add-users-btn Button{
    background-color: white;
    color: black;
    border-radius: 0px !important;
   }

   .add-users-btn Button:hover{
    background-color: white;
    color: black;
   }

  .user-details{
    /* border: 1px solid red; */
    margin-bottom: 10px;
  }

.no-data-img{
  /* style={{ width: '80%', height: 'auto', maxHeight: '500px' }} */
  /* border: 1px solid red; */
  height: auto;
  width: 100%;
}

.card-cont-img{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-inside-content{
  /* border: 1px solid green; */
}

.card-inside-image{
  /* border: 1px solid blue; */
}

.card-inside-image img{
/* height: 70px !important;
width: 100px !important; */
height: 10rem;
}

/* Users.module.css */

.pagination-controls {
  margin: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 45px;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  /* border-radius: 4px; */
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #0056b3;
}

.pagination-current-page {
  margin: 0 10px;
  font-weight: bold;
}


/* Additional styling for spinner and other components */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

/* Users.module.css */

.search-input {
  width: 300px;
  margin-bottom: 20px;
  border: 1px solid gray;
  padding: 10px;
  text-indent: 15px;
  border-radius: 5px;
}

.search-icon{
  /* border: 1px solid red; */
  margin-top: 7px;
  position: absolute;
  margin-left: 5px;
  font-size: 20px;
}

/* You can define additional styles as needed */

/* Users.module.css */
.link-to-basket-button {
  background-color: #04AA6D; /* Background color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 8px 12px; /* Padding for spacing */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Animation on hover */
}

.link-to-basket-button:hover {
  background-color: #038a59; /* Darker green on hover */
}

.main-h3{
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 10px;
  color: #1565C0;
}

.table-pagination{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F8F8;
}

.current-page{
  /* border: 1px solid blue; */
  margin-left: 10px;
  /* background-color: #1565C0; */
  background-color: white;
  color: black;
  /* color: white; */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12);
}

.form-search{
  position: relative;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  transition: all 1s;
  width: 300px;
  height: 50px;
  background: white;
  box-sizing: border-box;
  border-radius: 25px;
  /* border: 1px solid #5f7082 !important; */
  padding: 5px;
  margin-top: 30px;
}

.form-input-search{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42.5px;
  line-height: 30px;
  outline: 0;
  border: 1px solid green;
  display: block;
  font-size: 1em;
  border-radius: 20px;
  padding: 0 20px;
}

.form-input-icon{
  box-sizing: border-box;
  padding: 10px;
  width: 35.5px;
  height: 35.5px;
  position: absolute;
  margin-right: 5px;
  margin-top: 3px;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: #44E31E;
  text-align: center;
  font-size: 1.2em;
  /* transform: all 1s; */
  transition: all 0.5s ease;
}

.form-search:hover .form-input-icon{
  /* background: purple; */
  background: #EAFBE5;
  color: #44E31E;
}

.form-main-search{
  border: 1px solid #ffffff;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.basket-names{
  /* border: 1px solid red; */
  width: 200px;
}

.assign-basket{
  border: 1px solid rgb(219, 219, 219);
  padding: 10px;
}

.basket-status-main{
  /* border: 1px solid red; */
  height: 40px;
  width: 120px;
}

.basket-card{
  height: '30px';
  width: '30px';
  margin-right: '10px';
  border-radius: '50%';
  background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);
}

.para-basket-card{
  font-size: 20px;
}

.basket-card-name{
  /* border: 1px solid red !important; */
  font-size: 15px !important;
  background-color: black !important;
  color: white !important;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Adjust this value as needed */
}

.basket-card-popup-name{
  /* border: 1px solid red !important; */
  font-size: 15px !important;
  background-color: black !important;
  color: white !important;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popover-content {
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.card-inside-content{
  /* border: 1px solid red; */
}

.basket-card-edit-nav{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-edit-bask{
  /* border: 1px solid red; */
  margin-bottom: 10px;
}

.prodskel{
  /* border: 1px solid red; */
  margin: 10px;
  height: 5rem;
  width: 97.7%;
}

.prodoneskel{
  /* border: 1px solid red; */
  height: 15rem;
  /* display: flex; */
  /* justify-content: space-between; */
  /* gap: 2rem; */
  /* width: 100%; */
}

.prod-main-skel{
  /* border: 1px solid blue; */
  display: flex;
  /* width: 100%; */
  /* gap: 2rem; */
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 10px;
}

.main-community-head{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-count-main{
  /* border: 1px solid red; */
  /* color: gray; */
  color: #e5e9b4;
  font-size: 12px;
}

.card-inside-image p{
  /* border: 1px solid red; */
  font-size: 100px;
  /* color: rgb(224, 207, 113); */
  color: rgb(23, 67, 84);
}

.no-records-container{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: gray;
}

.no-records-container p{
  text-align: center;
}

.no-records-icon{
  /* border: 1px solid red; */
  margin: 0 auto;
  font-size: 80px;
}

.users-notfoundicon{
  color: gray;
}

.main-filter-prods{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.filter-dropdown{
  /* border: 1px solid red; */
}

.main-filter-for-bsadd{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.main-filter-for-bsadd input{
  border: none;
  padding: 10px;
  border-radius: 25px;
  background-color: #5F9EA0;
  color: white;
}

.main-filter-for-bsaddtwo{
  /* border: 1px solid red; */
}

.main-filter-for-bsaddtwo-btn{
  border: none;
  padding: 11px;
  width: 100px;
  border-radius: 25px;
}

.main-filter-for-bsaddtwo-btn:hover{
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  cursor: pointer;
}