.orders-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .orders-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
    max-width: 80%; /* Adjust based on your preference */
    overflow: hidden; /* Prevent overflowing content */
  }
  
  .orders-modal-title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .orders-modal-content p {
    margin-bottom: 10px;
    font-size: 16px;
    color: #666;
  }
  
  .orders-modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #666;
    font-size: 24px;
  }

  /* OrderDetails.module.css */

.order-details-container {
  /* max-width: 600px; */
  margin: 0 auto;
}

.order-details-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 10px;
  /* border: 1px solid rgb(219, 219, 219); */
  padding: 10px;
}

.header-form{
  /* border: 1px solid green; */
  padding: 10px;
  /* color: #44E31E; */
  /* border-bottom: 1px solid rgb(220, 219, 219); */
  /* background-color: #EAFBE5; */
      /* background-color: #006666; */
      background-color: #30A9BC;
      text-transform: uppercase;
      color: white;

  border-radius: 5px;
}
/* Additional styling can be added as needed */

.header-form-new{
  padding: 20px;
  color: #1565C0;
}

.upd-status{
  /* border: 1px solid red; */
  margin: 20px !important;
}

.chip-main{
  width: 100px;
}

.details-main-head{
  border-bottom: 1px solid rgb(239, 239, 239);
  display: flex;
  gap: 5px;
}

.details-para-one{
  /* border: 1px solid blue; */
}

.details-para-two{
  /* border: 1px solid green; */
}

.icon-order{
  color: #1565C0;
  font-size: 18px;
}

.assign-delivery-boy{
  /* border: 1px solid red; */
  width: 200px;
}

.select-drop-text{
  color: rgb(255, 255, 255) !important;
  background-color: #1565C0 !important;
}

/* Orders.module.css */

.active-tab {
  background-color: #000000; /* Active tab background color */
  color: #fff; /* Active tab text color */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin as needed */
  border-radius: 4px;
}

.inactive-tab {
  background-color: #f8f9fa; /* Inactive tab background color */
  color: #6c757d; /* Inactive tab text color */
  border: 1px solid #ccc; /* Inactive tab border */
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adjust margin as needed */
}

.inactive-tab:hover {
  background-color: #e9ecef; /* Hover background color for inactive tab */
  color: #6c757d; /* Hover text color for inactive tab */
}

.main-tabs-switch{
  /* border: 1px solid red; */
  padding-left: 10px;
}

.filter-tab-main{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}