.change-password{
    /* border: 1px solid red; */
    margin: 10px !important;
}

.heading-pass{
    color: #3005AF;
    /* border-bottom: 1px solid rgb(237, 237, 237) !important; */
    /* margin-bottom: 10px; */
}

.submit-pass{
    background-color: #3005AF !important;
    border-radius: 30px !important;
    width: 100px !important;
}

.submit-cancel{
    /* background-color: #340374 !important; */
    border-radius: 30px !important;
    width: 100px !important;
}