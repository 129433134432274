/* Users.module.css */
body{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }

.users-main{
    /* background-color: #F5F5F5; */
    background-color: white;
    color: #343A40;
    border: 1px solid white;
    /* border: 1px solid red; */
}

.users-main form {
  /* max-width: 600px; */
  /* margin: auto; */
  margin: 10px;
}

.users-main label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.users-main input,
.users-main textarea {
  width: 98%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.users-main textarea {
  resize: vertical;
}

.users-main button:hover {
  background-color: #2184b4;
}

.error-text {
  color: #e74c3c;
  margin-top: 5px;
}

.users-main h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #343A40;;
    border-bottom: 1px solid rgb(234, 232, 232);
    padding-bottom: 5px;
    text-align: center;
    /* background-color: #343A40; */
    /* background-image: url(../../../Assets//media-bg.jpg); */
  }

  .form-container{
    /* border: 1px solid red; */
    /* width: 50%; */
    /* margin: 0 auto; */
    /* display: flex; */
    width: 97%;
    display: flex;
    justify-content: center;
  }

  .form-inputs{
    /* border: 1px solid rgb(227, 227, 227); */
    width: 100%;
    /* margin: auto; */
    padding: 20px;
    /* margin-top: 50px; */
    /* margin-left: 60px; */
    /* position: relative; */
  }

  .form-inputs select{
    border: 1px solid rgb(219, 219, 219);
    padding: 10px;
    width: 100%;
    /* margin-left: 4px; */
    border-radius: 5px;
  }

  .tree-main{
    /* border: 1px solid red; */
    /* position: absolute; */
    width: 10%;
  }

  .tree-last{
    /* border: 1px solid red; */
    /* margin-bottom: 50px; */
    margin-top: 550px;
    text-align: start;
    width: 20%;
  }

  @media screen and (max-width:750px){
    .tree-main{
      display: none;
    }

    .tree-last{
      display: none;
    }

    .form-inputs{
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1100px){
    .image-and-nutrition-container{
      display: flex;
      flex-direction: column;
    }
  }

.form-image {
  width: 100%; 
}

.form-image img {
  width: 100%; 
  height: 20rem; 
  display: block; 
}

.form-btn-main{
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  margin: 5px;
}

.form-btn-main Button{
  background-color: #343A40 !important;
    color: #fff !important;
    padding: 5px 10px !important;
    border: none !important;
    border-radius: 2px !important;
    cursor: pointer !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
}

.main-add-btn{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.main-add-btn Button{
  background-color: black;
  color: white;
}

.main-add-btn Button:hover{
  background-color: black;
  color: white;
}

.add-pro-btn {
  padding: 10px;
  font-size: 20px;
  /* background-color: #1E40AF; */
  background-color: #2980b9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
  /* margin-top: 10px; */
}

.CiSquarePlus {
  margin-left: 5px; 
}

.products-table {
  width: 90%;
  border-collapse: collapse;
  /* margin-top: 20px; */
  margin-bottom: 40px;
}

.category-table{
  /* border: 1px solid red; */
  margin-bottom: 35px;
}

.products-table th {
  /* border: none; */
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

/* .products-table td:hover{
 background-color: #1E40AF;
} */

.products-table td{
  /* border-left: none; */
  /* border-right: none; */
  border-bottom: 1px solid #e8e8e8;
  /* border-bottom: 1px solid rgb(232, 232, 232); */
}


.product-row:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.products-table-head {
  /* background-color: #343A40; */
  /* background-color: rgb(186, 186, 186); */
  /* background-color: rgb(236, 236, 236); */
  /* background-color: #FFFFFF; */
  /* color: #fff; */
  color: black;
  border-bottom: 1px solid #e8e8e8;
}

.products-table img {
  max-width: 100px; 
  max-height: 100px; 
  border-radius: 5px;
  object-fit: cover;
}



/* Products.module.css */
.product-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* border: 1px solid red; */
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  margin-bottom: 16px;
  margin: 10px;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  /* width: 480px; */
  width: 40%;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card img {
  width: 130px; /* Adjust the image width as needed */
  height: auto;
  object-fit: cover;
  border: 4px solid rgb(196, 195, 195);
}

.product-card-content {
  padding: 16px;
  flex: 1;
}

.product-card h5 {
  color: #333;
  font-size: 1.2rem; /* Adjust font size as needed */
  margin-bottom: 8px; /* Add margin as needed */
}

.product-card p {
  color: #666;
  font-size: 1rem; /* Adjust font size as needed */
  margin-bottom: 4px; /* Add margin as needed */
}

.product-card-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #f0f0f0;
}

.product-card-buttons button {
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.edit-button {
  background-color: transparent !important;
  color: black !important;
}

.delete-button {
  background-color: transparent !important;
  color: black !important;
}

.edit-button:hover {
  background-color: #007bff !important;
  color: #fff !important; /* Edit button hover color */
}

.delete-button:hover {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.main-key-val{
  /* border: 1px solid green; */
  display: flex;
}

.key-val{
  /* border: 1px solid rgb(238, 238, 238); */
  /* border: 1px solid red; */
  height: 15rem;
  height: fit-content;
  margin-left: 5px;
  border-radius: 5px;
  width: 100%;
  /* background-color: #eaeaea; */
}

.first-two-divs{
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nutri-cont-main{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}

.text-area{
  border: 1px solid rgb(196, 196, 196);
}

.inp-key-val{
  /* border: 1px solid green; */
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.key-val input{
  /* border: 1px solid red; */
  margin: 5px;
  width: 40%;
}

.text-input{
  /* border: 1px solid red !important; */
  width: 150px !important;
}

.text-num-input{
  /* border: 1px solid red !important; */
  width: 50px !important;
}


.key-val-add{
  border: none;
  /* margin-left: 40px; */
  /* margin-top: 10px; */
  font-size: 30px;
  color: rgb(95, 95, 152);
  background-color: transparent;
  /* border: none; */
}

.key-remove{
  /* border: 1px solid red; */
  /* padding: 10px; */
  margin-left: 5px;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px;
  margin-top: 5px;
}

.key-remove:hover{
  background-color: rgb(232, 105, 105) !important;
  transition: background-color 0.3s ease-in-out;  
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 5px;
}

.expand-button{

}

.product-nutri-val{
  
}

.input-metric-val{
  /* border: 1px solid red; */
}

.input-metric-val select{
  /* border: 1px solid blue; */
  width: fit-content;
  position: absolute;
  padding: 5px;
  margin-top: 0.9px;
  height: 38px;
  border: none;
  margin-top: 10px;
  margin-left: 5px;
}

.input-metric-val input{
  border: 1px solid rgb(232, 232, 232);
  text-indent: 70px;
  padding: 20px;
  border-radius: 5px;
}

.metric-new-price{
  /* border: 1px solid red; */
}

.metric-new-price input{
  border: 1px solid rgb(232, 232, 232);
  padding: 20px;
  border-radius: 5px;
}

.image-and-nutrition-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  margin: 20px;
  /* border: 1px solid red; */
  /* background-color: #054646; */
  /* background-color: #1565C0; */
  background-color: white;
  /* margin: 20px;
  padding: 20px; */
}

.nutri-val-main{
  /* border: 2px solid rgb(250, 0, 0); */
  text-align: center;
  margin: 5px;
  /* background-color: white; */
  /* background-color: #bcadad; */
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
}

.nutri-val-main h2{
    color: #44E31E;
    text-transform: uppercase;
    padding: 10.0px;
    background-color: #EAFBE5;
    font-size: 18px;
}

.product-image {
  /* max-width: 200px !important; Adjust the maximum width as needed */
  height: 17.7rem !important;
  /* border: 4px solid rgb(230, 230, 230) !important; */
  /* margin: 10px; */
  margin-left: 10px !important;
  margin-right: 10px !important;
  /* border-radius: 5px !important; */
  /* background-color: #cbdecb; */
  /* box-shadow: rgba(147, 165, 179, 0.3) 0px 1px 2px 0px, rgba(200, 220, 235, 0.15) 0px 2px 6px 2px !important; */
  /* aspect-ratio: 3/4; */
  width: max-content;
  /* object-fit: contain !important; */
  /* margin-top: 50px; */
}

.nutritional-values-new {
  /* margin-left: 20px; */
  /* border: 1px solid rgb(255, 0, 0); */
  width: 100%;
  /* color: rgb(255, 255, 255); */
  height: 23rem;
  padding: 5px;
  /* background-color: white; */
  /* background-color: #1565C0; */
  background-color: #EAFBE5;
  color: #44E31E;
  margin: 5px;
}

/* .move-to-some{
  background: radial-gradient(circle at 10% 20%, rgb(4, 159, 108) 0%, rgb(194, 254, 113) 90.1%);
  color: black;
  padding: 4px;
  font-size: 10px;
  border-radius: 2px;
} */


.nutritional-values {
  /* margin-left: 20px; */
  /* border: 1px solid rgb(72, 0, 255); */
  width: 100%;
  color: gray;
  height: 17.8rem;
  background-color: white;
  margin-right: 10px;
  margin-top: 14px;
}

.header-nutri-val{
  /* border: 1px solid red; */
  margin-top: 3px;
  text-align: center;
}

.nutritional-values ul{
  /* border: 1px solid green; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
}

.nutritional-values ul li{
  border: 1px solid rgb(226, 226, 226);
  box-shadow: rgba(147, 165, 179, 0.3) 0px 1px 2px 0px, rgba(200, 220, 235, 0.15) 0px 2px 6px 2px;
  width: 150px;
  padding: 15px;
  color: #1565C0;
  font-weight: 600;
}

/* Styling for the nutritional values list */
.nutritional-values h2 {
  font-size: 18px;
  /* margin-bottom: 10px; */
  /* border-bottom: 1px solid rgb(230, 230, 230); */
  text-align: center;
  padding: 6.5px;
  text-transform: uppercase;
  /* margin: 5px; */
}

.nutritional-values ul {
  list-style: none;
  padding: 0;
}

.nutritional-values li {
  margin-bottom: 8px;
}

.metric-new-ok{
  border: 1px solid red;
}

.metric-new-ok button{
  border: 1px solid blue;
  padding: 20px;
  width: 120px;
  background-color: #1565C0;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 5px;
  color: white;
}

.edit-details-button{
  background-color: #4caf50; /* Green */
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.edit-details-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.delete-details-button{
  background-color: #f44336; /* Red */
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.delete-details-button:hover{
  background-color: #d32f2f; /* Darker red on hover */ 
}

.basket-add-product-btn{
  /* border: 1px solid red; */
  display: flex;
  justify-content: end;
  align-items: center;
}

.basket-add-product-btn Button{
  background-color: #EAFBE5;
  color: #44E31E;
}

.basket-add-product-btn Button:hover{
  background-color: #EAFBE5;
  color: #44E31E;
}

.basket-add-product-btn Button{
  /* border: 1px solid blue; */
  padding: 10px;
  height: 30px;
  margin-bottom: 20px;
  margin-right: 4px;
}

.main-add-product-btn{
  /* border: 1px solid red; */
  margin-bottom: 7px;
  margin-left: 1px;
}

.main-add-product-btn Button{
  background-color: white;
  color: black;
}

.main-add-product-btn Button:hover{
  background-color: white;
  color: black;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  z-index: 1000; /* Ensure the backdrop is above other elements */
}

.modal {
  position: absolute;
  width: 500px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal h2{
  /* border: 1px solid red; */
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(207, 207, 207);
  width: 100%;
}

.modal-content {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.modal-input{
  /* border: 1px solid red; */
  /* width: 200px; */
}

.modal-button {
  margin-top: 20px;
}

.new-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure the modal is above the backdrop */
  width: 400px;
}

.new-modal-content {
  text-align: center;
}

.new-modal-content h2 {
  margin-bottom: 20px;
  color: #1565C0;
}

.new-modal-content label {
  display: block;
  margin-bottom: 10px;
}

.new-modal-content input {
  width: 90%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.new-modal-content button {
  margin-top: 10px;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.new-modal-content button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.ed-mod-one{
  /* border: 1px solid red; */
  position: absolute;
  margin-top: 17px;
  padding: 8px;
  /* margin-right: 20px; */
  margin-left: 90px;
  width: 100px;
  border: none;
}

.ed-mod-two{
  /* border: 1px solid blue !important; */
  padding: 10px !important;
  position: static;
}

.last-inp{
  /* border: 1px solid red !important; */
  padding: 10px !important;
}

.last-cancel-ok{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.cancel-mod{
  background-color: rgb(199, 199, 199) !important;
}

.cancel-mod:hover{
  background-color: gray !important;
}

/* css for form and textfields */

.product-form{
  /* border: 1px solid red; */
  margin: 10px;
}

.main-prod-add-new{
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button-container{
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-left: 5px;
}

.button-container Button{
  width: 100%;
  margin-top: 5px;
}

/* Style for the product image */
.product-image {
  /* max-width: 100%; Ensure image does not exceed container width */
  height: auto; /* Keep aspect ratio */
  display: block; /* Ensure image is displayed as a block element */
  /* margin-top: 10px;  */
  margin-left: 0px;
}

/* Style for the image label */
.image-label {
  margin: 5px; /* Add space between label and image */
  /* font-weight: bold; */
  color: gray;
}

.image-label-two {
  margin: 10px !important;  /* Add space between label and image */
  /* font-weight: bold; */
  color: gray;
}

.main-grid-box{
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
}

.box-main{
  /* border: 1px solid red; */
  margin: 10px;
  display: flex;
  justify-content: space-between;
}

.box-textfields{
  /* border: 1px solid blue; */
  margin: 5px !important;
}

.image-main-add{
  border: 1px solid rgb(229, 229, 229);
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-top: 10px;
  border-radius: 4px;
}

.image-btn{
  border: 1px solid red;
  margin: 5px;
}

.product-images{
  /* border: 1px solid red; */
  height: 100px !important;
  width: 100px !important;
  object-fit: contain !important;
}

.product-wrapper {
  padding: 15px 15px 40px;
  background-color: #fff;
  border-radius: 4px;
  display: grid;
  gap: 0.5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  width: 90%;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.product-wrapper > h3 {
  border-bottom: 1px solid #ddd;
  padding: 0 0 5px;
  margin: 0 0 15px;
  font-size: 1.3rem;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
  font-weight: 500;
  color: #0077b6;
}

.product-wrapper form {
  padding: 0 0.5rem;
  /* display: grid; */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem 2rem;
  align-items: baseline;
}

.variant-list{
  border: 1px solid rgb(212, 212, 212);
}

.variant-item{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.form-control{
  /* border: 1px solid black; */
}

.form-control select{
  /* border: 1px solid red !important; */
  position: absolute;
  border: none;
  margin: 2px;
}

.category-table td.description {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error-variant-text{
  color: red;
  padding-left: 10px;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensures it covers the entire height of the form container */
  /* border: 1px solid red; */
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.form-btn-new-main{
  /* border: 1px solid red; */
  width: 100%;
  margin:  0 auto;
}
