/* Dashboard.module.css */

.users-maind{
    background-color: #8884d8 !important;
}

.dashboard-main-one {
    display: flex;
    justify-content: center;
    /* margin: 20px; */
    /* border: 2px solid black; */
}

.dashmain-one-left {
    /* flex: 100%; */
    min-height: 200px; /* Adjust the height as needed */
    /* You can add other styling properties for dashmain-one-left here */
    /* border: 1px solid yellow; */
    margin: 10px;
    /* width: 100%; */
    display: flex;
    justify-content: center;
}

/* Update Dashboard.module.css */

.bar-chart-container {
    padding: 20px;
  }
  
  .bar {
    fill: url(#colorGradient); /* Applying linear gradient fill */
  }
  
  .axis-text {
    font-size: 14px; /* Adjust the font size of axis labels */
  }
  
  .tooltip {
    background-color: #ffffff; /* Tooltip background color */
    border: 1px solid #8884d8; /* Tooltip border color */
    color: #8884d8; /* Tooltip text color */
  }
  
  .first-cont{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .chart-wrapper{
    /* border: 1px solid rgb(188, 188, 188) !important; */
    width: auto;
  }

  /* Area Chart */
.recharts-wrapper {
  position: relative;
}

.recharts-surface {
  overflow: visible;
}

.recharts-cartesian-axis-line {
  stroke: #ccc;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-cartesian-axis-tick {
  font-size: 12px;
  fill: #666;
}

.recharts-legend-item {
  font-size: 14px;
}

.recharts-legend-item-text {
  fill: #333;
}

.recharts-area {
  fill-opacity: 0.8;
}

.recharts-tooltip-cursor {
  fill: none;
  stroke: #666;
}

/* Tooltip */
.recharts-tooltip-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  color: #333;
}

.recharts-default-tooltip {
  font-size: 14px;
  font-family: Arial, sans-serif;
}

/* Define CSS classes for legend colors */
.registrations-legend {
  fill: url(#colorRegistrations);
}

.visits-legend {
  fill: url(#colorVisits);
}

.orders-legend {
  fill: url(#colorOrders);
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  /* color: #ecddb8; */
}

.custom-tooltip p {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
}

.custom-tooltip p:first-child {
  font-weight: bold;
}

.custom-tooltip p:not(:first-child) {
  color: #555; /* Color for regularized and running flow values */
}

.custom-tooltip p:not(:first-child)::before {
  content: "\2022"; /* Bullet point */
  color: #A52788; /* Color for regularized */
  margin-right: 5px;
}

.custom-tooltip p:not(:first-child:last-child)::after {
  content: "\2022"; /* Bullet point */
  color: #63CCF2; /* Color for running flow */
  margin-left: 5px;
}

.graphs-heading{
  /* border: 1px solid red; */
  /* margin-left: 40px; */
  color: #44E31E;
  /* margin-top: 20px; */
  margin: 10px;
  background-color: #EAFBE5;
  padding: 8px;
  border-radius: 4px;
  text-indent: 10px;
}
.no-data-container{
  /* border: 1px solid red; */
  text-align: center;
}