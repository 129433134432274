.remainder{
    /* border: 1px solid red; */
    color: #1565C0;
    font-size: 20px;
}

.remainder-btn{
    background-color: transparent;
    border: none;
}

.remainder-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .remainder-btn:hover {
    transform: scale(1.1);
  }
  
  .remainder {
    transition: color 0.3s ease;
  }
  
  .remainder-icon:hover {
    color: #ff9800; /* Change to the desired color */
    /* border: 1px solid red !important; */
  }

  .remainder-icon{
    color: #1565C0;
    font-size: 20px;
    /* border: 1px solid red; */
  }
  
  .remainder-check{
    color: green;
    font-size: 40px;
  }

  .total-wallet-price{
    text-align: end;
    /* border: 1px solid red; */
    padding: 10px;
    font-size: 20px;
    /* color: #ff9800; */
    border-bottom: 1px solid rgb(232, 232, 232);
    width: fit-content;
    margin: 10px;
  }

  .main-wallet{
    /* border: 1px solid red; */
    width: 30%;
    margin: 10px;
    background-color: #03A9F4;
    /* background-image: url(../../../Assets/BG\ 2.png); */
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .walletimg{
    height: 50px;
  }

  .wallet-sub-main{
    /* border: 1px solid red; */
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px;
    color: white;
  }

  .chip-design-wallet{
    width: 100px;
  }