/* AddBasketForm.css */

body{
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.add-basket-form-container {
    /* max-width: 600px; */
    width: 100%;
    margin: 0 auto;
  }
  
  .add-basket-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .add-basket-form input,
  .add-basket-form select {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 8px;
    box-sizing: border-box;
    border: 1px solid rgb(222, 222, 222);
  }
  
  /* .add-basket-form button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  } */

  .delete-product-single{
    /* border: 1px solid red; */
    border: none;
    background-color: transparent;
    color: red;
    /* padding: 10px; */
    font-size: 20px;
    margin-top: 3px;
    cursor: pointer;
  }

  /* .delete-product-single:hover{
   color: rgb(210, 3, 3);
  } */

  .add-pro-btn-cancel{
    /* border: 1px solid red !important; */
    /* margin: 0 !important; */
    height: 36px !important;
    margin: 4px !important;
    background-color: transparent !important;
    color: rgb(255, 198, 10) !important;
    border: none !important;
    box-shadow:  none !important;
    font-size: 25px !important;
  }

  .del-pro-btn-cancel{
    /* border: 1px solid red !important; */
    /* margin: 0 !important; */
    height: 36px !important;
    margin: 4px !important;
    background-color: transparent !important;
    color: rgb(255, 10, 10) !important;
    border: none !important;
    box-shadow:  none !important;
    font-size: 25px !important;
  }
  
  /* AddBasketFormTotal.css */
  
  .add-basket-form-total {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }

  .add-basket-form-total p {
    font-size: 14px;
    text-indent: 10px;
  }
  
  .add-basket-sub-main{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
  }
  
  .main-inp-basket{
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }

  .product-input-new{
    border: 1px solid rgb(203, 202, 202) !important;
    padding: 10px !important;
    margin-top: 8px !important;
    border-radius: 4px !important;
    height: 38px !important;
  }

  .product-row Select{
    /* border: 1px solid green !important; */
  }

  .main-inp-basket Select{
    /* border: 1px solid red !important; */
  }
  
  .product-select-list{
    /* border: 1px solid red; */
    width: 200px;
    margin-top: 3px;
  }

  .basket-inp-metric{
    border: 1px solid red;
    display: flex;
    align-items: center;
  }

  .metric-type-select{
    border: 1px solid green;
    position: absolute !important;
  }

  .metric-value-inp{
    border: 1px solid blue !important;
    padding: 10px !important;
    width: 200px !important;
    text-indent: 100px !important;
  }

  .basket-inp-qty{
    /* border: 1px solid green; */
    display: flex;
  }

  .basket-inp-qty input{
    border: 1px solid rgb(219, 219, 219);
    width: 150px !important;
    border-radius: 4px;
  }

  .basket-inp-qty select{
    /* border: 1px solid yellow; */
    position: absolute;
    margin-left: 3px;
    width: 70px;
    margin-top: 7px;
    border: none;
  }

  .pro-name-inp{
    /* border: 1px solid blue !important; */
    /* text-indent: 70px; */
    padding: 10px !important;
  }

  .basket-price-container{
    /* width: fit-content; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: 1px solid red; */
    border-bottom: 1px solid rgb(236, 236, 236);
    /* justify-content: space-between; */
    padding: 12px;
  }

  .basket-price-container input{
    /* border: none; */
    border: 1px solid rgb(206, 205, 205);
    font-size: 15px;
    margin-top: 8px;
    /* position: absolute; */
    /* margin-left: 130px; */
    width: fit-content;
    border-radius: 4px;
  }

  .basket-price-container label{
    font-size: 13px;
    width: 100px;
    font-weight: 400;
  }

  .add-basket-sub-main Button{
    /* border: 1px solid red; */
    margin: 20px;
  }

  .error-message{
    color: red;
    /* border: 1px solid blue; */
    position: absolute;
    margin-top: 60px;
    font-size: 12px;
    font-weight: 500;
  }

  .assign-label{
    font-size: 12px;
  }

  .searchable-addbasket{
    font-size: 12px;
  }

  .vac-status-main{
    /* border: 1px solid red; */
  }

  .inside-vec-one{
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
  }

  .vac-status-basket{
    /* border: 1px solid black; */
    font-size: 15px;
    color: rgb(97, 96, 96);
    padding-top: 10px;
  }

  .vac-status-text-one{
    /* border: 1px solid black; */
    font-size: 15px;
    color: black;
    padding-top: 10px;
    margin-left: 5px;
  }

  .vac-status-guest{
    /* border: 1px solid black; */
    font-size: 15px;
    color: rgb(97, 96, 96);
    padding-top: 10px;
  }

  .vac-status-guest-icon{
     border: none;
     font-size: 25px;
     color: #1976D2;
     cursor: pointer;
     margin-left: 5px;
     background: none;
     margin-top: 4px;
  }

  .vac-span{
    font-size: 20px;
     color: #1976D2;
     /* padding-top: 10px; */
     cursor: pointer;
     margin-left: 5px;
     margin-bottom: 5px !important;
     margin-top: 15px;
     
  }

  .vac-status-guest-icon:hover{
    color: blue;
  }


  .vac-status-vacation{
    /* border: 1px solid black; */
    font-size: 15px;
    color: rgb(97, 96, 96);
    padding-top: 10px;
  }

  .vac-status-text-two{
    /* border: 1px solid black; */
    font-size: 15px;
    color: black;
    padding-top: 10px;
    margin-left: 5px;
  }

  .guest-status-main{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
  }

  .guest-status-main label{
    color: gray;
    font-size: 15px;
  }

  /* Style for input fields */
.basket-inp-field {
  width: 100%; /* Adjust the width to fit your design */
  padding: 8px; /* Adjust padding for comfort */
  margin-bottom: 10px; /* Add space between input fields */
  border: 1px solid #ccc; /* Subtle border for clarity */
  border-radius: 4px; /* Rounded corners for visual appeal */
  background-color: #f9f9f9; /* Light background color */
  font-size: 14px; /* Adjust font size for readability */
  font-family: Arial, sans-serif; /* Consistent font style */
}

/* Focus effect */
.basket-inp-field:focus {
  outline: none; /* Remove the default outline */
  border-color: #4CAF50; /* Highlight with a color on focus */
}

/* Container for each product's input group */
.product-input-container {
  display: flex;
  flex-direction: column; /* Stacking inputs vertically */
  padding: 10px; /* Space around the container */
  margin-bottom: 15px; /* Space between product containers */
  border: 1px solid #e0e0e0; /* Subtle border around container */
  border-radius: 4px; /* Rounded corners for the container */
  background-color: #fff; /* White background for separation */
}

/* Button style for deleting products */
.del-pro-btn-cancel {
  background-color: #ff4d4d; /* Red color for delete button */
  color: #fff; /* White text */
  border: none; /* No border */
  padding: 8px 12px; /* Padding for comfortable click area */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
}

/* Button hover effect */
.del-pro-btn-cancel:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}

.basket-inp-qty input{
  /* border: 1px solid red; */
  width: 100px;
}

.basket-inp-price input{
  /* border: 1px solid red; */
  width: 100px;
}

.basket-inp-metric input{
  /* border: 1px solid red; */
  width: 100px;
}

.main-inp-basket Select{
  /* border: 1px solid red !important; */
}


  /* background-image: linear-gradient(25deg, #173589 0%, #111429 56%); */

  /* some css code */

  /* Style for each product container */
.product-container {
  padding: 15px; /* Add padding for separation */
  border: 1px solid #e0e0e0; /* Subtle border */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 15px; /* Space between each product */
  background-color: #f9f9f9; /* Light background */
}

/* Style for each input field */
.product-input {
  width: 100%; /* Full width of container */
  padding: 8px; /* Comfortable padding */
  margin-bottom: 10px; /* Space between inputs */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Adjust font size */
  font-family: Arial, sans-serif; /* Consistent font style */
  background-color: #fff; /* White background */
}

/* Label styling */
.product-label {
  display: block; /* Ensure labels are block-level */
  margin-bottom: 5px; /* Space between label and input */
  font-size: 14px; /* Adjust font size */
  font-family: Arial, sans-serif; /* Consistent font style */
}

/* Delete button style */
.del-pro-btn-cancel {
  background-color: #ff4d4d; /* Red color */
  color: #fff; /* White text */
  padding: 8px; /* Comfortable padding */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  margin-top: 10px; /* Space above button */
}

/* Button hover effect */
.del-pro-btn-cancel:hover {
  background-color: #ff1a1a; /* Darker red on hover */
}


.basket-users{
  /* border: 1px solid red; */
  margin: 10px;
}

.basket-name-field{
  margin: 10px;
}

.basket-name-input{
  /* padding: 15px; */
  height: 40px;
  border-radius: 5px;
}

.dialogue-box{
  border: 1px solid red !important;
}

.baske-card{
 width: '60px';
 height: '60px';
 margin-right: '10px';
 border-radius: '50%';
 background-color: 'gray'; 
 border: '1px solid gray';
}