/* Faq.module.css */

.accordionContainer {
    margin: 10px; /* Margin between each accordion */
}

.accordionSummary {
    /* background-color: #7ab0d1 !important;  */
    background-color: #1592c0 !important;
    /* background-color: #ecc69b !important; */
    padding: 10px 15px;
    /* border-bottom: 1px solid #cccccc; */
    /* border-radius: 4px !important; */
    /* margin: 5px !important; 
    margin-top: 10px !important; */
}

.accordionQuestion {
    font-weight: bold; 
    /* color: #1565C0;  */
    color: #ffff;
    font-size: 16px; 
    border-top: none !important;
    box-shadow: none !important;
}

.accordionDetails {
    padding: 10px 15px; 
    
}

.accordionAnswer {
    color: #666666; 
    font-size: 14px;
    width: 100%;
    word-break: break-all;
}

.accordian-ans-head{
    color: #7B1FA2;
    font-size: 18px;
    font-weight: 500;
    /* border-bottom: 1px solid #7B1FA2; */
}

.accordionButtonsContainer{
    /* border: 1px solid red; */
    display: flex !important;
    justify-content: flex-end !important;
}

.editButton{
    color: blue !important;
}

.deleteButton{
    color: red !important;
}

/* General container styles */
.edit-formContainer {
    max-width: 600px; /* Adjust max-width based on design */
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header styles */
.edit-header {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

/* Form group styles */
.edit-formGroup {
    margin-bottom: 15px;
    /* border: 1px solid red; */
    margin: 10px;
}

/* Label styles */
.edit-formGroup label {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
}

/* Input field styles */
.edit-formControl {
    width: 100%;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.2s;
    font-weight: bold; 
    color: #1565C0; 
    font-size: 16px; 
}

.edit-formControl:focus {
    border-color: #0066cc;
    outline: none;
}

.edit-formControl-ans {
    width: 100%;
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    transition: border-color 0.2s;
    /* font-weight: bold;  */
    color: black; 
    font-size: 16px; 
    font-family: sans-serif;
}

.edit-formControl-ans:focus {
    border-color: #0066cc;
    outline: none;
}

/* Textarea styles */
.edit-formGroup textarea {
    min-height: 100px; /* Adjust height as needed */
    resize: vertical; /* Allow vertical resizing */
}

/* Button styles */
.edit-buttonContainer {
    display: flex;
    justify-content: center;
    margin: 20px;
    gap: 10px;
}

.edit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
}

.edit-buttonPrimary {
    background-color: #4CAF50; /* Green */
    color: white;
}

.edit-buttonPrimary:hover {
    background-color: #45a049;
}

.edit-buttonSecondary {
    background-color: #f44336; /* Red */
    color: white;
}

.edit-buttonSecondary:hover {
    background-color: #e57373;
}

.main-add-form{
    /* border: 1px solid red; */
    margin: 10px;
}

/* Styles for Faq component */
.accordionContainer {
  margin-bottom: 16px; /* Adjust spacing between accordion items */
}

.accordionSummary {
  background-color: #f0f0f0; /* Example background color */
}

.accordionQuestion {
  font-weight: bold; /* Example font-weight */
  width: 100%;
  word-break: break-all;
}

.accordionButtonsContainer {
  display: flex;
  align-items: center;
}

.editButton,
.deleteButton {
  margin-left: 8px; /* Adjust spacing between edit and delete buttons */
}

.accordionDetails {
  background-color: #fafafa; /* Example background color */
  padding: 12px; /* Adjust padding inside accordion details */
}

.accordian-ans-head {
  font-weight: bold; /* Example font-weight for answer heading */
}

/* Styles for Confirmation Modal */
.edit-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-modal {
  background-color: #fff; /* Modal background color */
  width: 400px; /* Adjust modal width */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Example box shadow */
}

.modal-content {
  text-align: center; /* Center align content inside modal */
}

.modal-last-div {
  margin-top: 16px; /* Adjust margin between modal content and actions */
  text-align: center; /* Center align actions */
}
