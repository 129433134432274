.coupon-drop{
    /* border: 1px solid red; */
    width: 120px;
}

/* CouponsModal.css */
.modal-box {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 50% !important;
    padding: 20px !important; /* Padding inside modal */
    border-radius: 8px !important; /* Rounded corners */
    /* background-image: url(../../../Assets/Discount.gif); */
    box-shadow: 24 !important; /* Box shadow for modal */
    outline: none !important; /* No border outline */
}

/* CouponsModal.css */

/* Class for active status (green color) */
.status-active {
    color: green;
}

/* Class for inactive status (red color) */
.status-inactive {
    color: red;
}

.popper{
    color: gold !important;
    font-size: 30px;
}

.couponp-first{
    display: flex;
}

.second-coupon-main{
    border-right: 1px solid rgb(213, 213, 213);
    margin: 10px;
}

.third-coupon-main{
    /* border: 1px solid red; */
    margin-top: 5px;
}

.modal-box img{
    font-size: 20px !important;
    /* border: 2px solid green; */
    height: 200px;
    /* margin-top: 100px; */
    /* margin-left: 50px; */

}

.discount-one{
    font-size: 20px !important;
}

.modal-title {
    font-size: 1.5rem !important; /* Font size of title */
    font-weight: bold !important; /* Bold title */
    margin-bottom: 16px !important; /* Margin below title */
    color: #6636ea !important;
    /* color: rgb(0, 0, 0) !important; */
    text-align: center !important;
    /* border-bottom: 1px solid rgb(227, 227, 227) !important; */
    display: flex;
}

.modal-content {
    margin-bottom: 16px !important; /* Margin below content */
    font-size: 1rem !important; /* Font size of content */
    color: #666 !important;
    /* color: rgb(0, 0, 0) !important; */
}

.close-button {
    margin-top: 16px !important; /* Margin above close button */
    display: block !important; /* Block-level element */
    margin-left: auto !important; /* Align to the right */
    background-color: #f50057 !important; /* Background color of button */
    color: #fff !important; /* Text color of button */
    border-radius: 4px !important; /* Rounded corners */
    padding: 8px 16px !important; /* Padding inside button */
    cursor: pointer !important; /* Pointer cursor on hover */
}

/* Hover effect for the close button */
.close-button:hover {
    background-color: #c51162 !important; /* Hover background color */
}

.Discount-type{
    color: gray;
    margin-left: 5px;
}